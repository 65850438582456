import React from 'react'
import { graphql } from 'gatsby'
import CheckLink from '../components/checkLink'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Page from '../templates/page'
import CaseStudy from '../templates/caseStudy'

export const query = graphql`
  query page404 {
    prismicPage(uid: { eq: "404" }) {
      ...PrismicPageFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  return (
    <Layout>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} />
      <div className='text-3xl z-10 flex flex-1 flex-col items-center justify-center text-center text-white bg-gradient-to-b from-warmGray-800 to-coolGray-600 py-32'>
        {pageData.page_title && (
          <div className='text-center font-black leading-tight md:text-6xl text-5xl'>{pageData.page_title.text}</div>
        )}
        {pageData.page_text && (
          <div className='block text-2xl' dangerouslySetInnerHTML={{__html:pageData.page_text.html}} />
        )}
        {(pageData.page_button_link && pageData.page_button_text) && (
          <CheckLink className='bg-gradient-to-r from-red-500 to-red-400 button hover:from-red-600 mt-4' link={pageData.page_button_link}>{pageData.page_button_text}</CheckLink>
        )}
      </div>
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: Page,
    case_study: CaseStudy
  },
})
